import { useQuery } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { hasUserClaimedLotteryTicketToday } from '../users.service';
export const useLotteryTicket = (enabled = true) => {
    return useQuery({
        queryKey: usersKeys.hasUserClaimedLotteryTicketToday(),
        queryFn: () => hasUserClaimedLotteryTicketToday(),
        enabled,
    });
};
