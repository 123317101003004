import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { claimLotteryTicket } from '../users.service';
export const useClaimLotteryTicket = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => {
            return claimLotteryTicket();
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: usersKeys.hasUserClaimedLotteryTicketToday(),
            });
        },
    });
};
