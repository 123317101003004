import { api, apiV2 } from '../services';
export const resetClaims = async (subdomain, questId) => {
    const res = await apiV2.review.resetClaims({ params: { subdomain }, body: { questId } });
    if (res.status == 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('resetClaims failed');
    }
};
export const listReviews = async (options) => {
    const res = await apiV2.review.list({
        ...options,
        query: {
            ...options.query,
            status: options.query?.status ? JSON.stringify(options.query?.status) : undefined,
            userId: options.query?.userId ? JSON.stringify(options.query?.userId) : undefined,
            reviewerId: options.query?.reviewerId ? JSON.stringify(options.query?.reviewerId) : undefined,
            questId: options.query?.questId ? JSON.stringify(options.query?.questId) : undefined,
        },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('listReviews failed');
    }
};
export const getReview = async (options) => {
    const res = await apiV2.review.get(options);
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getReview failed');
    }
};
export const getUserStats = async (options) => {
    const res = await apiV2.review.getUserStats(options);
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getUserStats failed');
    }
};
export const getReviewEvents = async (options) => {
    const res = await apiV2.review.listEvents(options);
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getReviewEvents failed');
    }
};
export const updateReviews = async (subdomain, body) => {
    const res = await apiV2.review.create({ params: { subdomain }, body });
    if (res.status == 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('updateReviews failed');
    }
};
export const cancelReview = async (subdomain, claimedQuestIds) => {
    return updateReviews(subdomain, { claimedQuestIds, status: 'pending', bonusXP: 0 });
};
export const exportReviews = ({ subdomain, body, }, options) => api.post(`/communities/${subdomain}/claimed-quests/export`, {
    ...options,
    body,
});
export const getRecentReviewComments = async (subdomain, questId) => {
    const comments = await api.get(`/communities/${subdomain}/claimed-quests/${questId}/comments`);
    const uniqueComments = Array.from(new Set(comments));
    return uniqueComments;
};
export const getReviewAlerts = async (options) => {
    const res = await apiV2.review.getReviewAlerts(options);
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getReviewAlerts failed');
    }
};
export async function getPendingReviews(subdomain) {
    const result = await api.get(`/communities/${subdomain}/claimed-quests/pending-count`);
    return result.count;
}
