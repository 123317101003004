import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { claimQuest, questKeys } from '..';
import { usersKeys } from '../../users';
/**
 * Hook for claim a quest
 */
export const useClaimQuest = (moduleId, questId) => {
    const subdomain = getSubdomain();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: claimQuest(subdomain, questId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
            queryClient.invalidateQueries({ queryKey: usersKeys.challengeProgress() });
        },
    });
};
