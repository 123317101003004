import { useQuery } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { getUserChallengeProgress } from '../users.service';
export const useChallengeProgress = (enabled = true) => {
    return useQuery({
        queryKey: usersKeys.challengeProgress(),
        queryFn: () => getUserChallengeProgress(),
        enabled,
    });
};
